import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';

export const useTableResponsive = (columns, config) => {
  return [
    {
      ..._.get(columns, '0'),
      responsive: ['xs'],
      render: (rec) => {
        let items = _.filter(columns, (item) => !_.castArray(_.get(item, 'responsive')).includes('xs'));
        if (_.isFunction(_.get(config, 'render'))) {
          return _.get(config, 'render')(rec, items);
        }
        return (
          <Row gutter={[8, 16]} {..._.get(config, 'rowProps')}>
            {_.map(items, (item, index) => {
              const responsiveGridProps = _.get(item, 'responsiveGridProps');
              if (_.isFunction(item.render)) {
                let colProps = _.get(config, 'colProps');
                colProps = _.isFunction(colProps) ? colProps(item) : colProps;
                return (
                  <Col key={`${item.key}_${index}`} {...colProps} {...responsiveGridProps}>
                    {item.render(rec)}
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        );
      },
      ...config,
    },

    ..._.map(columns, (item) => {
      return {
        responsive: ['sm'],
        ...item,
      };
    }),
  ];
};

export default useTableResponsive;
