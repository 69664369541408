import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useTableResponsive from '@vl/hooks/useTableResponsive';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';

import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Button } from '@uz/unitz-components-web/Button';
import cx from 'classnames';

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            subscription_plans_loading: hook((ctx) => {
              const [version, $version] = React.useState(0);
              return {
                version,
                $version,
                reload: () => $version(version + 1),
              };
            }),
            subscription_plans: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const condition = {
                where: {
                  account_id: { _eq: account_id },
                },
              };
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    ctx.apply('loadingModel.setLoading', 'fetchData');
                    const client = await getClient();
                    const resData = await client.request(
                      gql`
                        query b2b_subscription_plan($where: b2b_account_billing_bool_exp = {}) {
                          b2b_subscription_plan (
                            order_by: { display_order: asc_nulls_last }
                          ){
                            name
                            description: name
                            price
                            plan_id
                            management: plan_id
                            management_course_qos: price
                            management_student_qos: price
                            currency_iso_code
                            display_order
                          }
                          b2b_account_billing(where: $where) {
                            id
                            payment_method
                            information
                            subscription {
                              id
                              plan_id
                            }
                          }
                        }
                      `,
                      { ...condition }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return resData;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id, ctx.get('subscription_plans_loading.version')]
              );
              const items = _.get(data, 'b2b_subscription_plan');
              const plans = _.map(items, (data) => {
                return {
                  // plan details
                  ..._.pick(data, ['name', 'description', 'price', 'plan_id']),
                  // management details
                  management: '',
                  ..._.pick(data, [
                    'management_course_qos',
                    'management_student_qos',
                  ]),
                  data,
                };
              });
              const subscription = _.get(data, 'b2b_account_billing.0.subscription');
              const billing = _.get(data, 'b2b_account_billing.0');
              return {
                plans,
                subscription,
                billing,
              };
            }),
          }
        }
      ]
    ]
  },
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            allColumns: hook((ctx) => {
              const route = useRoute();
              const plans = ctx.get('subscription_plans.plans');
              const plansByPlanId = _.keyBy(plans, 'plan_id');
              const rowKeys = _.keys(_.first(plans));
              const activePlanId = ctx.get('subscription_plans.subscription.plan_id') || 'unitz_plan_free';
              const activeSubscription = plansByPlanId[activePlanId];
              const columns = useTableResponsive([
                {
                  // title: ctx.apply('i18n.rt', 'Compare plans'),
                  key: 'index',
                  responsiveGridProps: {
                    xs: { span: 24, order: 0 },
                  },
                  render: (...args) => {
                    const [item, index, rowIndex] = args;
                    return renderPropsComposer(
                      {
                        matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'name',
                        render: (props) => (
                          <div className="font-bold text-xl">
                            {ctx.apply('i18n.rt', 'Compare plans')}
                          </div>
                        ),
                      },
                      {
                        matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'management',
                        render: (props) => (
                          <div className="font-bold text-xl">
                            {ctx.apply('i18n.rt', 'Management')}
                          </div>
                        ),
                      },
                      {
                        matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'management_course_qos',
                        render: (props) => (
                          <div className="">
                            {ctx.apply('i18n.rt', 'Course')}
                          </div>
                        ),
                      },
                      {
                        matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'management_student_qos',
                        render: (props) => (
                          <div className="">
                            {ctx.apply('i18n.rt', 'Student')}
                          </div>
                        ),
                      },
                    )({ item, rowIndex });
                  },
                },
                ..._.compact(_.map(plans, (plan, index) => {
                  const planId = _.get(plan, 'plan_id');
                  const isActive = planId === activePlanId;
                  return {
                    // title: _.get(plan, 'name'),
                    key: planId,
                    responsiveGridProps: {
                      xs: { span: 24, order: 0 },
                    },
                    width: `${_.floor(100 / (_.get(plans, 'length') + 1))}%`,
                    className: cx({ 'bg-red-500 inactive': !isActive }, 'plan-cell'),
                    render: (...args) => {
                      const [item, index, rowIndex] = args;
                      return (<div className={cx({ 'plan-cell': !isActive }, 'text-center')}>
                        {renderPropsComposer(
                          {
                            matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'name',
                            render: (props) => (
                              <div className="font-bold text-xl">
                                {_.get(props, `item.${planId}`, '')}
                              </div>
                            ),
                          },
                          {
                            matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'plan_id',
                            render: (props) => {
                              const isUpgrade = _.get(plan, 'data.display_order') > _.get(activeSubscription, 'data.display_order');
                              return (
                                <div className="px-2 flex justify-center">
                                  <Button
                                    onClick={async () => {
                                      const userId = ctx.apply('authModel.getUserId');
                                      const account_id = ctx.apply('accountModel.getAccountId');
                                      const payment_method = ctx.get('subscription_plans.billing.payment_method');
                                      const billing_information = ctx.get('subscription_plans.billing.information');
                                      const canSubmit = !!payment_method && !!billing_information;
                                      if (!canSubmit) {
                                        let url = ctx.apply('routeStore.toUrl', 'toolAccountSettingsBillingPlanUpgrade');
                                        url = url || '/settings/billing/plan_upgrade';
                                        const toUrl = route.toLocale(url, {
                                          plan_id: _.get(plan, 'plan_id'),
                                        });
                                        // console.log('toUrl', toUrl);
                                        route.navigate(toUrl, { relace: true });
                                        return false;
                                      }
                                      const res = await fbFnsClient.getClient().post('payment-createSubscription', {
                                        user_id: userId,
                                        account_id,
                                        plan_id: planId,
                                      });
                                      console.log('res', res);
                                      ctx.apply('subscription_plans_loading.reload');
                                    }}
                                    type={isActive ? 'ghost' : 'primary'}
                                    // block={!isActive}
                                    block
                                    disabled={isActive}
                                  >
                                    {isActive ?
                                      `${ctx.apply('i18n.rt', 'Current plan')}` :
                                      `${isUpgrade ? ctx.apply('i18n.rt', 'Upgrade to') : ctx.apply('i18n.rt', 'Downgrade to')} ${_.get(plan, 'name')}`
                                    }
                                  </Button>
                                </div>
                              );
                            },
                          },
                          {
                            matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'price',
                            render: (props) => (
                              <div className="px-2">
                                <strong className="font-bold text-lg pr-1">{`${_.get(props, `item.${planId}`, '')}`}</strong>
                                {`${_.get(plan, 'data.currency_iso_code')}/${ctx.apply('i18n.rt', 'month')}`}
                              </div>
                            ),
                          },
                          {
                            matcher: (props) => rowKeys[_.get(props, 'rowIndex')] === 'data',
                            render: () => null,
                          },
                          {
                            matcher: () => true,
                            render: (props) => (
                              <div>
                                {_.get(props, `item.${planId}`, '')}
                              </div>
                            ),
                          },
                        )({ item, rowIndex })}
                      </div>);
                    },
                  };
                }))
              ]);
              return columns;
            }),
            tableName: hook(() => 'tblSubscriptionPlans'),

            tableColumn: hook((ctx) => {
              const plans = ctx.get('subscription_plans.plans', []);
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = ctx.get('allColumns');
              const rows = _.keys(_.first(plans));
              const dataSource = _.map(rows, (rowKey) => {
                const row = {};
                _.map(plans, (plan) => {
                  _.set(row, plan.plan_id, plan[rowKey]);
                });
                return row;
              });

              return {
                showHeader: false,
                bordered: false,
                pagination: false,
                rowSelection: false,
                dataSource,
                columns,
                allColumns,
              };
            }),
          }
        }
      ]
    ]
  },
});

export default bindData;
