import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Table, Input } from 'antd';
import styled from 'styled-components';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';

const StyledTable = styled(Table)`
  .ant-table-tbody > tr.ant-table-row:hover {
    
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    background: #ffffff !important;
  }
  .ant-table-tbody > tr > td.inactive {
    border-right: 4px solid #ffffff;
    border-left: 4px solid #ffffff;
    background: #f0f0f0 !important;
  }
`;

const Index = () => {
  return (
    <LoadingCheckWrapper>
      <DIV className="pageContainer">
        <StyledTable
          columns={ctx.get('tableColumn.columns')}
          dataSource={ctx.get('tableColumn.dataSource', [])}
          rowKey={(rec) => _.get(rec, 'id')}
          loading={ctx.get('paginationModel.isLoading')}
          pagination={ctx.get('tableColumn.pagination')}
          className="mx-2"
        />
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default displayName(Index);
